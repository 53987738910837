.MuiPaper-root .MuiBox-root .slider {
  overflow: hidden;
  white-space: nowrap;
  position: relative;
}

.MuiPaper-root .MuiBox-root .slider.scroll-text::before {
  content: "";
  position: absolute;
  width: 20px;
  height: 100%;
  background-image: linear-gradient(to right, rgba(37, 48, 66, 0.8), rgba(0, 0, 0, 0));
  z-index: 10;
  animation: 100ms linear 2s 1 forwards show-shadow;
  opacity: 0;
}

@keyframes show-shadow {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.MuiPaper-root .MuiBox-root .slider.scroll-text::after {
  content: "";
  position: absolute;
  right: 0;
  width: 20px;
  height: 100%;
  background-image: linear-gradient(to left, rgba(37, 48, 66, 0.8), rgba(0, 0, 0, 0));
  z-index: 10;
  /* duration | timing-function | delay | iteration-count | fill-mode | animation-name */
  animation: 100ms linear 2s 1 forwards show-shadow;
  opacity: 0;
}

.MuiPaper-root .MuiBox-root .slider.scroll-text .track-details-title {
  display: inline-block;
}

.MuiPaper-root .MuiBox-root .slider.scroll-text.short .track-details-title {
  /* duration | timing-function | delay | iteration-count | animation-name */
  animation: 4s linear 2s 1 scroll-start, 8s linear 6s infinite scroll-text;
}

.MuiPaper-root .MuiBox-root .slider.scroll-text.medium .track-details-title {
  animation: 6s linear 2s 1 scroll-start, 12s linear 8s infinite scroll-text;
}

.MuiPaper-root .MuiBox-root .slider.scroll-text.long .track-details-title {
  animation: 8s linear 2s 1 scroll-start, 16s linear 10s infinite scroll-text;
}

.MuiPaper-root .MuiBox-root .slider.scroll-text.extra-long .track-details-title {
  animation: 10s linear 2s 1 scroll-start, 20s linear 12s infinite scroll-text;
}

@keyframes scroll-start {
  from {
    transform: translateX(0%);
  }

  to {
    transform: translateX(-100%);
  }
}

@keyframes scroll-text {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(-100%);
  }
}
