.chorister {
  display: flex;
  flex-direction: column;
  padding: 0 0 2.5rem;
  width: 210px;
}

.chorister-image-and-name-container {
  position: relative;
  width: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.chorister .chorister-image-and-name-container .chorister-delete-button {
  position: absolute;
  top: 0;
  right: calc(50% - 4.5rem);
  cursor: pointer;
}

.chorister img {
  width: 120px;
  height: 120px;
  object-fit: cover;
  border-radius: 50%;
  object-position: 50% 20%;
}

.chorister .chorister-full-name {
  margin-top: 0.2rem;
  margin-bottom: 0;
  font-weight: 900;
  font-size: 0.9rem;
  text-align: center;
}

.chorister .chorister-senior-label {
  margin-top: 3px;
  padding: 0rem 0.5rem;
  color: rgb(15, 41, 80);
  background-color: rgb(240, 190, 100);
  border-radius: 15px;
  font-size: 0.7rem;
}

.chorister .chorister-years {
  color: rgb(239, 167, 33);
  margin-top: 2px;
  margin-bottom: 0;
  font-weight: 600;
  font-size: 0.7rem;
}

.chorister .btn.btn-outline-primary {
  height: 120px;
  width: 120px;
  border-radius: 50%;
}

.chorister .btn.btn-outline-primary:hover {
  color: rgb(15, 41, 80);
}

.chorister .btn.btn-outline-primary .svg-inline--fa.fa-circle-plus {
  height: 100%;
  width: 100%;
}

@media only screen and (max-width: 800px) {
  .chorister {
    width: 160px;
  }

  /* .chorister-image-and-name-container {
    width: 100px;
  }

  .chorister img {
    width: 100px;
    height: 100px;
  } */
}

@media only screen and (max-width: 513px) {
  .chorister {
    width: 150px;
  }

  .choristers-container .chorister:nth-child(odd) {
    align-items: flex-start;
  }

  .choristers-container .chorister:nth-child(even) {
    align-items: flex-end;
  }
}

@media only screen and (max-width: 363px) {
  .chorister {
    align-items: center !important;
    width: 100%;
  }

  .chorister-image-and-name-container {
    width: 100%;
  }
}
