.contact-form-container {
  margin-left: 2rem;
  width: 50%;
}

.contact-form {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
}

.contact-form .form-label.required {
  display: flex;
  align-items: flex-start;
}

.contact-form .form-label.required svg {
  color: red;
  height: 0.5rem;
  padding-top: 0.5%;
}

.contact-form button[type="submit"] {
  align-self: flex-end;
}

.contact-form button[type="submit"] svg {
  margin-left: 0.3rem;
}

@media only screen and (max-width: 767px) {
  .contact-form-container {
    margin-left: 0;
    margin-bottom: 2rem;
    width: 100%;
  }
}
