.edit-staff-member-form-container > .staff-member-title {
  font-size: calc(0.6rem + 1.5vw);
  color: rgb(239, 167, 33);
  text-shadow: 0 0 5px black;
  margin-bottom: 0.7rem;
  width: fit-content;
}

.edit-staff-member-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.edit-staff-member-form .mb-3 {
  width: 100%;
}

.upload-container {
  display: flex;
  align-items: flex-end;
}

.edit-staff-member-form .mb-3 .btn.btn-primary {
  width: unset;
  padding: 0.2rem 0.7rem;
  margin-top: 0.2rem;
  margin-right: 0.2rem;
}

.edit-staff-member-form .progress {
  margin-top: 0.5rem;
  width: 100%;
}

.edit-staff-member-form .btn.btn-primary {
  width: 80px;
}
