.loading-container {
  margin-top: 15vh;
  padding: 1rem;
  display: flex;
  justify-content: center;
}

.loading-image-wrapper {
  height: 75vh;
}

.loading-container img {
  height: 55%;
  mix-blend-mode: difference;
}
