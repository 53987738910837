.collapse .nav-link {
  color: rgb(240, 190, 40) !important;
}

[role="main navigation"] .nav-link {
  font-size: 1.8rem;
  font-weight: 900;
  padding: 1rem;
  margin: 0 0.4rem;
}

[role="main navigation"] .nav-link:hover {
  color: rgb(53, 79, 119) !important;
}

[role="main navigation"] .nav-link > span {
  position: relative;
}

[role="main navigation"] .nav-link:hover > span::after,
[role="main navigation"] .selected-nav-link > span::after {
  content: "";
  width: 100%;
  height: 0.125rem;
  position: absolute;
  top: calc(100% + 0.125rem);
  left: 0;
}

[role="main navigation"] .nav-link:hover > span::after {
  background-color: rgb(117, 134, 160);
  opacity: 0.8;
}

[role="main navigation"] .selected-nav-link > span::after {
  background-color: rgb(53, 79, 119) !important;
  opacity: 1 !important;
}

@media only screen and (max-width: 1130px) {
  [role="main navigation"] .nav-link {
    font-size: 1.7rem;
  }
}

@media only screen and (max-width: 1050px) {
  [role="main navigation"] .nav-link {
    font-size: 1.6rem;
  }
}

@media only screen and (max-width: 1020px) {
  [role="main navigation"] .nav-link {
    font-size: 1.5rem;
  }
}

@media only screen and (max-width: 950px) {
  [role="main navigation"] .nav-link {
    font-size: 1.4rem;
  }
}

@media only screen and (max-width: 920px) {
  [role="main navigation"] .nav-link {
    font-size: 1.3rem;
  }
}

@media only screen and (max-width: 890px) {
  [role="main navigation"] .nav-link {
    font-size: 1.2rem;
  }
}

@media only screen and (max-width: 790px) {
  [role="main navigation"] .nav-link {
    font-size: 1.1rem;
  }
}

@media only screen and (max-width: 767px) {
  [role="main navigation"] .nav-link {
    font-size: 1.7rem;
  }

  .collapse:not(.show) .nav-link {
    color: rgba(254, 248, 238, 0) !important;
  }
}

@media only screen and (max-device-width: 600px) {
  [role="main navigation"] .nav-link {
    font-size: 1.4rem;
  }

  .collapse:not(.show) .nav-link {
    color: rgba(254, 248, 238, 0) !important;
  }
}
