.navbar-light .cart-nav-container {
  position: absolute;
  bottom: 1rem;
  right: 1rem;
}

.navbar-light .cart-button {
  height: 45px;
  padding: 0;
  border: none;
  color: rgb(240, 190, 40);
}

.navbar-light .cart-button:hover {
  color: rgb(53, 79, 119);
  background-color: initial;
}

.navbar-light .cart-button:focus {
  box-shadow: 0 0 0 0.25rem rgba(53, 79, 119, 0.70);
}

.navbar-light .cart-button .cart-number-of-items {
  position: absolute;
  top: 75%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0;
  font-weight: 900;
  font-size: 1.2rem;
  color: rgb(53, 79, 119);
  text-shadow: 0 0 1px white;
}

.navbar-light .cart-button:hover .cart-number-of-items {
  color: rgb(240, 190, 40);
  text-shadow: 0 0 1px black;
}

.navbar-light .cart-button.cart-highlight::before {
  content: "";
  z-index: -1;
  left: -0.6em;
  top: 2.3em;
  border-width: 2px;
  border-style: solid;
  border-color: rgb(240, 190, 40);
  position: absolute;
  border-right-color: transparent;
  width: 140%;
  height: 1.1em;
  transform: rotate(2deg);
  opacity: 0.7;
  border-radius: 50%;
  padding: 0.1em 0.25em;
}

.navbar-light .cart-button.cart-highlight::after {
  content: "";
  z-index: -1;
  left: -0.6em;
  top: 2.3em;
  padding: 0.1em 0.25em;
  border-width: 2px;
  border-style: solid;
  border-color: rgb(240, 190, 40);
  border-left-color: transparent;
  border-top-color: transparent;
  position: absolute;
  width: 141%;
  height: 1.4em;
  transform: rotate(-1deg);
  opacity: 0.7;
  border-radius: 50%;
}

.navbar-light .cart-button > svg {
  height: 100%;
}

.navbar-light .cart-nav-container .cart-button-pointer {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-bottom: 12px solid rgb(240, 190, 40);
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  top: 112%;
  right: 34%;
  margin-left: -5px;
  margin-top: -1px;
}
