.add-chorister-form-container > .choir-name {
  font-size: calc(0.625rem + .9vw);
  color: rgba(239, 167, 33, 0.7);
  margin-bottom: 0.7rem;
}

.add-chorister-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.add-chorister-form .mb-3 {
  width: 100%;
}

.upload-container {
  display: flex;
  align-items: flex-end;
}

.add-chorister-form .mb-3 .btn.btn-primary {
  width: unset;
  padding: 0.2rem 0.7rem;
  margin-top: 0.2rem;
  margin-right: 0.2rem;
}

.add-chorister-form .progress {
  margin-top: 0.5rem;
  width: 100%;
}

.add-chorister-form .btn.btn-primary {
  width: 80px;
}
