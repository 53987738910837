.modal {
  margin-top: 0;
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  max-width: 100%;
  max-height: 100%;
  z-index: 1100;
}

.modal .overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: hsla(0, 0%, 0%, 0.85);
}

.modal .modal-content {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 70%;
  max-height: 80%;
  overflow: auto;
  padding: 2rem 2rem;
  background-color: rgb(15, 41, 80);
  box-shadow: 0 0 8px rgb(240, 190, 100);
}

.modal .close-modal {
  position: relative;
  top: -15px;
  right: calc(15px - 100%);
  width: 35px;
  font-weight: 900;
  font-size: 1.2rem;
  opacity: 0.7;
  background: none;
  border: none;
  transition: opacity 0.2s ease;
  cursor: pointer;
  color: white;
  text-shadow: 0 0 1px black;
}

.modal .close-modal:hover {
  opacity: 1;
}

.modal .close-modal:focus {
  outline: none;
}

.modal .close-modal:active {
  border: none;
}
