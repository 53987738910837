.media .media-tab-videos .videos-container .video-container {
  width: 47%;
}

.media .media-tab-videos .videos-container .video-container:nth-child(odd) {
  margin-right: 6%;
}

@media only screen and (max-width: 720px) {
  .media .media-tab-videos .videos-container .video-container {
    display: block;
    width: 100%;
  }

  .media .media-tab-videos .videos-container .video-container:nth-child(odd) {
    margin-right: 0;
  }
}
