.choirs {
  padding: 2rem;
}

.choirs-heading-and-logo-container {
  position: relative;
}

h2.choirs-heading {
  font-size: 1.5rem;
  font-weight: 900;
  padding-bottom: 1rem;
  margin-bottom: 1.75rem;
  position: relative;
  width: fit-content;
}

h2.choirs-heading::after {
  position: absolute;
  content: "";
  width: 70%;
  height: 0.125rem;
  top: calc(100% + 0.025rem);
  left: 0;
  background-color: rgba(0,0,0,.4);
}

img.choir-logo {
  height: 80px;
  position: absolute;
  top: -20px;
  left: 200px;
  border-radius: 5px;
  box-shadow: 0px 0px 3px rgb(240, 190, 100);
}

@media only screen and (max-width: 450px) {
  img.choir-logo {
    height: 65px;
    top: -18px;
    left: 190px;
  }
}

@media only screen and (max-width: 400px) {
  img.choir-logo {
    height: 60px;
    top: -15px;
    left: 188px;
  }
}

@media only screen and (max-width: 380px) {
  img.choir-logo {
    height: 55px;
    top: -13px;
    left: 185px;
  }
}

@media only screen and (max-width: 350px) {
  img.choir-logo {
    height: 45px;
    top: -8px;
  }
}

@media only screen and (max-width: 330px) {
  .choirs-heading-and-logo-container {
    position: unset;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  img.choir-logo {
    position: unset;
    height: 50px;
    margin-bottom: 1rem;
    margin-right: 0.5rem;
  }
}
