.home-choirs {
  padding: 10vh 2rem;
  background-color: rgb(4, 27, 61);
}

.home-choirs .home-choirs-title {
  text-align: center;
  font-weight: 900;
  font-size: calc(0.9rem + 3.2vw);
  margin-bottom: 3rem;
  opacity: 0;
}

.home-choirs .home-choirs-title.is-visible {
  opacity: 1;
  transition: opacity 1s ease-out;
}

.home-choirs .home-choirs-list {
  display: flex;
}

.home-choirs .home-choirs-list .home-choir-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
  flex: 1 1 0;
}

.home-choirs .choir-link {
  text-align: center;
  width: 70%;
}

.home-choirs .choir-link.nighthawk-honors-logo-link {
  width: 45%;
}

.home-choirs .home-choir-image {
  width: 100%;
  object-fit: cover;
  border-radius: 20px;
}

.home-choirs .home-choir-image.nighthawk-honors-logo {
  width: 50%;
}

.home-choirs .home-choirs-list .home-choir-description {
  font-size: calc(0.5rem + 1.5vw);
  font-weight: 900;
  width: 100%;
  padding: 2rem;
  text-align: center;
}

.home-choirs .home-choirs-list .home-choir-image,
.home-choirs .home-choirs-list .home-choir-description,
.home-choirs .choirs-link {
  transform: translateY(-30%);
  opacity: 0;
}

.home-choirs .home-choirs-list .home-choir-image.is-visible,
.home-choirs .home-choirs-list .home-choir-description.is-visible,
.home-choirs .choirs-link.is-visible {
  opacity: 1;
  transform: none;
  transition: opacity 1s ease-out, transform 1s ease-out;
}

.home-choirs .choirs-link {
  text-decoration: none;
  display: flex;
  justify-content: center;
  margin-top: 3rem;
}

.home-choirs .choirs-link .go-to-choirs-button {
  font-size: calc(0.5rem + 1.5vw);
  display: block;
  margin-bottom: 0.3rem;
  color: rgb(4, 27, 61);
  background-color: rgb(240, 190, 80);
}

.home-choirs .choirs-link .go-to-choirs-button:hover,
.home-choirs .choirs-link .go-to-choirs-button:active {
  color: rgb(240, 190, 80);
  background-color: rgb(4, 27, 61);
  border-color: rgb(240, 190, 80);
}

.home-choirs .choirs-link .go-to-choirs-button:focus {
  box-shadow: 0 0 0 0.25rem rgba(240, 190, 80, 0.5);
}

@media only screen and (max-width: 767px) {
  .home-choirs .home-choirs-list {
    flex-wrap: wrap;
  }

  .home-choirs .home-choirs-list .home-choir-container {
    flex: initial;
  }

  .home-choirs .home-choirs-list .home-choir-container:nth-child(n+2) {
    margin-top: 0.5rem;
  }
}
