.page-not-found {
  margin-top: 0;
  min-height: 95vh;
  padding: 3rem 5rem;
  font-size: 1.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50%;
}

.page-not-found main {
  text-shadow: 0 0 5px black;
  font-size: 3rem;
  color: gold;
}

@media only screen and (max-width: 600px) {
  .page-not-found {
    padding: 1rem;
    flex-direction: column;
    align-content: center;
    justify-content: center;
  }
}
