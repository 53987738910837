.event-month {
  margin-bottom: 1.2rem;
  border-bottom: 1px dotted;
  border-color: rgba(239, 167, 33, 0.6);
}

.event-month:last-child {
  border: none;
}

.event-month h5.event-month-heading {
  text-align: right;
  margin-bottom: 0;
  text-shadow: 0 0 3px black;
  font-size: 1.6rem;
}

.event-month ol.event-month-list {
  list-style: circle;
  margin-bottom: 0rem;
  margin-block-start: 0.5em;
  padding-inline-start: 1rem;
  line-height: 1.5rem;
}

.event-month ol.event-month-list li.event {
  margin-bottom: 0.7rem;
}

.event-month.preview {
  width: fit-content;
}

@media only screen and (max-width: 767px) {
  .event-month ol.event-month-list {
    padding-inline-start: 0;
  }
}
