.btn.btn-danger.delete-trash-icon {
  color: #ac2424;
  background-color: transparent;
  padding: 0;
  border: none;
  cursor: pointer;
}

.btn.btn-danger.delete-trash-icon:hover {
  color: #dc3545;
}

.btn.btn-danger.delete-trash-icon:focus {
  box-shadow: none;
  color: #dc3545;
}
