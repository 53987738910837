.media {
  padding: 2rem;
}

h2.media-heading {
  font-size: 1.5rem;
  font-weight: 900;
  padding-bottom: 1rem;
  margin-bottom: 1.75rem;
  position: relative;
  width: fit-content;
}

h2.media-heading::after {
  position: absolute;
  content: "";
  width: 70%;
  height: 0.125rem;
  top: calc(100% + 0.025rem);
  left: 0;
  background-color: rgba(0,0,0,.4);
}

.tab-pane .coming-soon {
  font-size: 3rem;
  margin-top: 1rem;
}

.media .media-pills-and-add-media-button-container {
  display: flex;
  align-items: center;
  margin: 2rem 0;
}

.media .nav.nav-pills {
  margin-right: 0;
}

.media .nav-pills .nav-item button.nav-link {
  color: rgb(240, 190, 100);
  border: 1px solid rgb(240, 190, 100);
  border-radius: 25px;
  margin-right: 0.5rem;
}

.media .nav-pills .nav-item button.nav-link:not(.active):hover {
  color: rgb(53, 79, 119);
  background-color: rgb(240, 190, 100);
}

.media .nav-pills .nav-item button.nav-link.active {
  color: rgb(15, 41, 80);
  background-color: rgb(240, 190, 100);
  border: 1px solid black;
}

@media only screen and (max-width: 600px) {
  .video-pills-and-add-media-button-container,
  .audio-pills-and-add-media-button-container {
    margin: 1rem 0;
  }

  .media .nav.nav-pills {
    display: flex;
    flex-direction: column;
  }

  .media .nav-pills .nav-item button.nav-link {
    margin-right: 0;
    margin-bottom: 0.3rem;
  }
  
  .media .nav-pills .nav-item:nth-last-child(1) button.nav-link {
    margin-bottom: 0;
  }
}