.login {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 8rem;
}

.form-control:disabled, .form-control[readonly] {
  cursor: not-allowed;
}
