.order-form-container {
  margin-left: 2rem;
  width: calc(45% - 2rem);
}

.order-form {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
}

.order-form .form-label.required {
  display: flex;
  align-items: flex-start;
}

.order-form .form-label.required svg {
  color: red;
  height: 0.5rem;
  padding-top: 0.5%;
}

.order-form .form-select.not-selected {
  color: rgb(110, 117, 124);
}

.order-form #orderFormPurchaseNotes {
  color: rgb(176 129 42) !important;
}

.order-form button[type="submit"] {
  align-self: flex-end;
}

.order-form button[type="submit"] svg {
  margin-left: 0.3rem;
}

@media only screen and (max-width: 767px) {
  .order-form-container {
    margin-left: 0;
    width: 100%;
  }
}
