.events {
  padding: 1rem;
  height: initial;
  min-height: initial;

  display: flex;
}

@media only screen and (max-width: 767px) {
  .events {
    flex-direction: column;
  }
}
