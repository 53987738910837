.swag-container {
  width: 54%;
  margin-left: 6%;
}

.swag-heading-and-add-swag-container {
  display: flex;
  align-items: flex-start;
}

.swag-container h2.swag-heading {
  font-size: 1.5rem;
  font-weight: 900;
  padding-bottom: 1rem;
  margin-bottom: 1.75rem;
  position: relative;
  width: fit-content;
}

.swag-container h2.swag-heading::after {
  position: absolute;
  content: "";
  width: 70%;
  height: 0.125rem;
  top: calc(100% + 0.025rem);
  left: 0;
  background-color: rgba(0,0,0,.4);
}

.swag-container .add-swag {
  margin-left: 2rem;
}

.swag-container .loading-container {
  margin-top: 0;
  justify-content: flex-start;
}

.swag-items {
  display: flex;
  flex-wrap: wrap;
}

@media only screen and (max-width: 1108px) {
  .swag-container {
    width: 47%;
  }
}

@media only screen and (max-width: 950px) {
  .swag-container {
    width: 40%;
  }
}

@media only screen and (max-width: 850px) {
  .swag-container {
    width: 35%;
  }
}

@media only screen and (max-width: 767px) {
  .swag-container {
    width: 100%;
    margin-top: 2rem;
    margin-left: 0;
  }
}
