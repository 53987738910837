.media-tab-choir-vibes .carousel-item img {
  height: 85%;
  object-fit: scale-down;
}

.media-tab-choir-vibes .carousel-item p {
  text-align: center;
  margin-top: 0.5rem;
}

.media-tab-choir-vibes .choir-vibes-photo-action-buttons {
  align-self: center;
}

.media-tab-choir-vibes .choir-vibes-photo-edit-button {
  margin: 0 4rem;
}
