.swag-item {
  height: 330px;
  width: 250px;
  margin-bottom: 2rem;
  margin-right: 2rem;
}

.swag-group, .swag-category {
  display: inline-block;
}

.swag-group {
  font-weight: 900;
  font-size: 1.2rem;
  margin-bottom: 0;
  height: 10%;
}

.swag-category {
  color: rgb(176 129 42);
  font-size: 0.7rem;
  margin-left: 0.1rem;
  margin-bottom: 0;
  height: 6%;
}

.swag-order-and-action-buttons {
  display: flex;
  justify-content: space-between;
}

.swag-order-button {
  display: block;
  margin-bottom: 0.3rem;
  color: rgb(240, 190, 80);
  border-color: rgb(240, 190, 80);
}

.swag-order-button:hover,
.swag-order-button:active {
  color: rgb(15, 41, 80);
  background-color: rgb(240, 190, 80);
}

.swag-order-button:focus {
  box-shadow: 0 0 0 0.25rem rgba(240, 190, 80, 0.5);
}

.swag-action-buttons .swag-item-edit-button {
  margin: 0 1rem;
}

.swag-image {
  height: 75%;
  width: 220px;
  max-width: 100%;
}
