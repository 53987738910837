.swag-and-support {
  padding: 2rem;
  display: flex;
}

@media only screen and (max-width: 767px) {
  .swag-and-support {
    flex-direction: column;
  }
}
