.add-or-edit-swag-item-form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.add-or-edit-swag-item-form-container .swag-item {
  margin: 0;
  margin-top: 0.5rem;
}

.add-or-edit-swag-item-form-container .swag-item-number {
  position: absolute;
  top: 4%;
  left: 4%;
  margin: 0;
}

.add-or-edit-swag-item-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 1rem;
}

.add-or-edit-swag-item-form .mb-3 {
  width: 100%;
}

.upload-container {
  display: flex;
  align-items: flex-end;
}

.add-or-edit-swag-item-form .mb-3 .btn.btn-primary {
  width: unset;
  padding: 0.2rem 0.7rem;
  margin-top: 0.2rem;
  margin-right: 0.2rem;
}

.add-or-edit-swag-item-form .progress {
  margin-top: 0.5rem;
  width: 100%;
}

.add-or-edit-swag-item-form .btn.btn-primary {
  width: 80px;
}
