.choir-blurb-and-edit-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.choir-blurb-and-edit-container .choir-blurb {
  line-height: 1.2;
  margin-bottom: 1.5rem;
}

.choir-blurb-and-edit-container .mb-3 {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.choir-blurb-edit-container-buttons button.btn {
  margin-top: 0.3rem;
}

.choir-blurb-and-edit-container .mb-3 .btn.btn-secondary {
  margin-right: 1rem;
}

.choir-blurb-and-edit-container button.btn.btn-outline-warning {
  margin-left: 0.4rem;
}

.choir-container .choir-video-select-label {
  font-style: italic;
  font-size: calc(0.6rem + 1.5vw);
  color: rgb(240, 190, 100);
}

.choir-container .form-select {
  max-width: 640px;
  border: 1px solid rgb(240, 190, 100);
}
