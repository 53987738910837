.home-hero {
  min-height: 80vh;
  height: fit-content;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50%;
}

.home-hero .title-container {
  background-color: rgb(255, 255, 255, 0.6);
}

.home-hero .title-container .title {
  font-size: 5rem;
  color: rgb(31, 51, 86);
  text-align: center;
  padding: 0.5rem 0;
  margin-bottom: 0;
  text-shadow: 0 0 5px white;
  opacity: 0;
  transform: translateY(-10px);
}

.home-hero .title-container .title.slide-down-and-fade-in {
  transform: translateY(0);
  opacity: 1;
  transition: transform 1.5s ease-in, opacity 1.5s ease-in;
}

.home-links {
  width: 100%;
  padding: 2rem 8rem 3rem;
  font-size: 1.5rem;
  color: white;
  display: flex;
  justify-content: space-around;
}

.home-links a {
  display: block;
  text-shadow: 0 0 2px black;
  margin-bottom: 1rem;
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  padding: 0.4rem;
  width: fit-content;
  text-decoration: none;
}

.home-links a:hover {
  color: rgb(240, 190, 40);
  font-style: italic !important;
}

.home-links .home-right {
  margin-left: 2rem;
}

.home-links .home-left .home-left-text-top {
  font-style: italic;
  transform: translateX(-100px);
}

.home-links .home-left .home-left-text-top.slide-right {
  transform: translateX(0);
  transition: transform 1.5s ease-out;
}

.home-links .home-right .home-right-text-top.bounce-up-and-down {
  animation: 1s 2 alternate-reverse bounce;
  animation-timing-function: ease-out;
}

@keyframes bounce {
  from {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-50px);
  }

  to {
    transform: translateY(0);
  }
}

.home-links .home-left .home-left-text-top.stand-up {
  font-style: normal;
}

.home-links .home-left .home-left-text-bottom,
.home-links .home-right .home-right-text-bottom {
  transform: translateY(200px);
}

.home-links .home-left .home-left-text-bottom.slide-up {
  transform: translateY(0);
  transition: transform 1s cubic-bezier(0, 0.6, 0, 0.92);
}

.home-links .home-right .home-right-text-bottom.slide-up {
  transform: translateY(0);
  transition: transform 1.5s cubic-bezier(0, 0.6, 0, 0.92);
}

@media only screen and (max-width: 872px) {
  .home-left-text-bottom {
    margin-top: 14rem;
  }
  .home-right-text-bottom {
    margin-top: 13rem;
  }
}

@media only screen and (max-width: 600px) {
  .home-hero .title-container .title {
    font-size: 4rem;
  }

  .home-links {
    padding: 1rem;
  }

  .home-left-text-bottom {
    margin-top: 16rem;
  }
  .home-right-text-bottom {
    margin-top: 15rem;
  }
}

@media only screen and (max-width: 439px) {
  .home-left-text-bottom {
    margin-top: 11rem;
  }
  .home-right-text-bottom {
    margin-top: 10rem;
  }
}

@media only screen and (max-device-width: 330px) {
  .home-hero .title-container .title {
    font-size: 3rem;
  }
}
