.navbar-light .cart-dropdown {
  position: absolute;
  top: 135%;
  right: -13%;
  height: 300px;
  width: 350px;
  background-color: rgb(240, 190, 40);
  border-radius: 7px;
  color: rgb(53, 79, 119);
  padding: 1rem;
  overflow: scroll;
}

.navbar-light .cart-dropdown .cart-dropdown-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.navbar-light .cart-dropdown .checkout-button-link {
  text-decoration: none;
  margin-top: -5px;
}

.navbar-light .cart-dropdown .checkout-button {
  color: rgb(15, 41, 80);
  background-color: rgb(240, 190, 80);
  border-color: rgb(15, 41, 80);
}

.navbar-light .cart-dropdown .checkout-button:hover,
.navbar-light .cart-dropdown .checkout-button:active {
  color: rgb(240, 190, 80);
  background-color: rgb(15, 41, 80);
}

.navbar-light .cart-dropdown .checkout-button:focus {
  box-shadow: 0 0 0 0.25rem rgba(15, 41, 80, 0.3);
}

.navbar-light .cart-dropdown .cart-dropdown-heading {
  font-weight: 900;
  font-size: 1.3rem;
  margin-bottom: 2rem;
}

.navbar-light .cart-dropdown .swag-support-button-link {
  text-decoration: none;
}

.navbar-light .cart-dropdown .cart-swag-container.cart-empty {
  min-height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 30%;
}

.navbar-light .cart-dropdown .go-to-swag-button {
  color: rgb(240, 190, 80);
  background-color: rgb(15, 41, 80);
  border-color: rgb(15, 41, 80);
}

.navbar-light .cart-dropdown .go-to-swag-button:hover,
.navbar-light .cart-dropdown .go-to-swag-button:active {
  color: rgb(15, 41, 80);
  background-color: rgb(240, 190, 80);
}

.navbar-light .cart-dropdown .go-to-swag-button:focus {
  box-shadow: 0 0 0 0.25rem rgba(15, 41, 80, 0.3);
}
