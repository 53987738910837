.home-videos {
  padding: 10vh 2rem;
  background-color: rgb(4, 27, 61);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.home-videos .video-container .video-heading-container {
  display: flex;
  justify-content: center;
}

.home-videos .video-container .video-heading-container .video-title-and-delete-container {
  margin-right: 1rem;
  margin-bottom: 0.4rem;
}

.home-videos .video-container .video-heading-container .video-details-container {
  margin-left: 1rem;
}

.home-videos .video-container .video-heading-container .video-details-container .video-sub-title,
.home-videos .video-container .video-heading-container .video-details-container .video-year {
  vertical-align: sub;
}

.home-videos .media-link {
  text-decoration: none;
  margin-top: 1rem;
}

.home-videos .video-container {
  width: 100%;
}

.home-videos .media-link .go-to-performances-button {
  font-size: calc(0.5rem + 1.5vw);
  display: block;
  margin-bottom: 0.3rem;
  color: rgb(4, 27, 61);
  background-color: rgb(240, 190, 80);
}

.home-videos .media-link .go-to-performances-button:hover,
.home-videos .media-link .go-to-performances-button:active {
  color: rgb(240, 190, 80);
  background-color: rgb(4, 27, 61);
  border-color: rgb(240, 190, 80);
}

.home-videos .media-link .go-to-performances-button:focus {
  box-shadow: 0 0 0 0.25rem rgba(240, 190, 80, 0.5);
}

.home-videos .video-container,
.home-videos .media-link {
  transform: translateY(-30%);
  opacity: 0;
}

.home-videos .video-container.is-visible,
.home-videos .media-link.is-visible {
  opacity: 1;
  transform: none;
  transition: opacity 1s ease-out, transform 1s ease-out;
}

.home-videos .home-page-video-select-form {
  margin-bottom: 2rem;
}
