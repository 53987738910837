.header-nav {
  justify-content: center;
  margin-left: 2rem;
}

.header-nav.navbar-collapse.collapsing {
  transition: none;
}

.header-nav.navbar-collapse.collapse.show {
  background-color: rgba(0, 0, 0, 0.85);
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  padding-bottom: 0.5rem;
}

.nav-links {
  align-items: center;
}

@media only screen and (max-height: 310px) {
  .collapse.show {
    height: calc(100vh - 4.6rem);
    overflow-y: scroll;
  }
}

@media only screen and (max-width: 850px) {
  .header-nav {
    justify-content: flex-end;
    margin-right: 1.5rem;
  }
}

@media only screen and (max-width: 767px) {
  .header-nav {
    margin-left: 0;
  }
}
