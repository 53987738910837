.media .audio-container {
  width: 47%;
  display: inline-block;
  margin-bottom: 2.5rem;
}

.media .media-tab-audios .audio-container:nth-child(odd) {
  margin-right: 6%;
}

@media only screen and (max-width: 767px) {
  .media .audio-container {
    width: unset;
    margin-right: 2rem !important;
  }
}

@media only screen and (max-width: 720px) {
  .media .audio-container {
    display: block;
    margin-right: 0 !important;
  }
}
