.toast-container {
  margin-top: 16vh;
}

div.toast-container.position-fixed {
  position: fixed !important;
}

.toast-body {
  color: black;
}

.toast.bg-success .toast-body {
  color: white;
  text-shadow: 0 0 1px black;
}
