.checkout {
  padding: 2rem;
}

h2.checkout-heading {
  font-size: 1.5rem;
  font-weight: 900;
  padding-bottom: 1rem;
  margin-bottom: 1.75rem;
  position: relative;
  width: fit-content;
}

h2.checkout-heading::after {
  position: absolute;
  content: "";
  width: 70%;
  height: 0.125rem;
  top: calc(100% + 0.025rem);
  left: 0;
  background-color: rgba(0,0,0,.4);
}

.checkout .checkout-cart-items-container {
  display: flex;
  flex-wrap: wrap;
}

.checkout .checkout-cart-items-container.is-empty {
  justify-content: center;
}

.checkout .swag-support-button-link {
  text-decoration: none;
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.checkout .go-to-swag-button {
  color: rgb(15, 41, 80);
  background-color: rgb(240, 190, 80);
  border-color: rgb(240, 190, 80);
}

.checkout .go-to-swag-button:hover,
.checkout .go-to-swag-button:active {
  color: rgb(240, 190, 80);
  background-color: rgb(15, 41, 80);
}

.checkout .go-to-swag-button:focus {
  box-shadow: 0 0 0 0.25rem rgba(15, 41, 80, 0.3);
}

.checkout #orderFormPurchaseNotes {
  margin-top: 0;
  margin-bottom: 1rem;
}

.checkout .submit-order-button {
  align-self: flex-end;
}
