.events-list-container {
  padding: 0.4rem 1rem 0;
  width: calc(30vw - 1rem);
  height: 75vh;
  overflow: scroll;
}

.events-list-container-heading-container {
  position: relative;
}

.events-list-container-heading-container h2.events-list-container-heading {
  text-align: center;
  margin-bottom: 1.7rem;
  font-size: calc(1.625rem + .9vw);
}

.events-list-container-heading-container .add-event {
  position: absolute;
  top: 0;
  right: 0;
}

@media only screen and (max-width: 875px) {
  .events-list-container-heading-container .add-event {
    right: unset;
    left: -10px;
  }
}

@media only screen and (max-width: 767px) {
  .events-list-container {
    margin-top: 3rem;
    width: calc(100vw - 2rem);
    padding-top: 1rem;
    padding-right: 0;
  }

  .events-list-container-heading-container .add-event {
    right: 0;
    left: unset;
  }
}
