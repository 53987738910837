body {
  font-family: PT Sans, sans-serif;
  font-size: 1rem;
	background-color: rgb(15, 41, 80);
	color: rgb(239, 167, 33);
}

main > section {
  margin-top: 15vh;
  min-height: 80vh;
  height: fit-content;
}

.toast-container {
  z-index: 1500;
}

.nav-tabs {
  border-bottom-color: #b8bdc2;
}

.nav-tabs .nav-link.active {
  border-color: #b8bdc2 #b8bdc2 rgb(15, 41, 80);
  background-color: rgb(15, 41, 80);
}

.nav-tabs .nav-link:not(.active):hover {
  border-color: #dee2e6 #dee2e6 #b8bdc2;
}

.nav-tabs .nav-item button.nav-link {
  color: rgb(239, 167, 33);
  font-size: calc(0.6rem + 1.5vw);
}

.nav-tabs .nav-item button.nav-link.active {
  text-shadow: 0 0 5px black;
}

.not-allowed {
  cursor: not-allowed;
}
