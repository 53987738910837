.home-swag-support {
  padding: 10vh 2rem;
}

.home-swag-support .home-swag-support-title {
  text-align: center;
  font-weight: 900;
  font-size: calc(0.9rem + 3.2vw);
  margin-bottom: 3rem;
  opacity: 0;
}

.home-swag-support .home-swag-support-title.is-visible {
  opacity: 1;
  transition: opacity 1s ease-out;
}

.home-swag-support .home-swag-support-container {
  display: flex;
  justify-content: space-around;
}

.home-swag-support .home-support-container {
  width: 45%;
  height: 8vh;
}

.home-swag-support .home-support-container .home-support-title,
.home-swag-support .home-swag-container .home-swag-title {
  font-size: calc(0.7rem + 2vw);
  font-weight: 900;
  width: 100%;
  padding: 0.5rem;
  text-align: center;
}

.home-swag-support .home-support-container .home-support-images {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.home-swag-support .home-support-container .home-support-image {
  text-align: center;
  object-fit: cover;
  border-radius: 20px;
  height: 100%;
}

.home-swag-support .home-support-container .home-support-images .swag-support-link {
  height: 100%;
}

.home-swag-support .home-support-container .home-support-images .swag-support-link:nth-child(even) {
  margin-left: 1rem;
}

.home-swag-support .home-support-title,
.home-swag-support .home-support-image,
.home-swag-support .home-swag-title,
.home-swag-support .home-swag-image,
.home-swag-support .swag-support-button-link {
  transform: translateY(-30%);
  opacity: 0;
}

.home-swag-support .home-support-title.is-visible,
.home-swag-support .home-support-image.is-visible,
.home-swag-support .home-swag-title.is-visible,
.home-swag-support .home-swag-image.is-visible,
.home-swag-support .swag-support-button-link.is-visible {
  opacity: 1;
  transform: none;
  transition: opacity 1s ease-out, transform 1s ease-out;
}

.home-swag-support .swag-support-link {
  text-decoration: none;
  text-align: center;
  color: rgb(239, 167, 33);
}

.home-swag-support .swag-support-link:hover {
  color: rgb(212, 152, 41);
}

.home-swag-support .home-swag-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 55%;
}

.home-swag-support .home-swag-container .home-swag-image {
  width: 50%;
}

.home-swag-support .swag-support-button-link {
  text-decoration: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 3rem;
}

.home-swag-support .swag-support-button-link .go-to-swag-support-button {
  font-size: calc(0.5rem + 1.5vw);
  display: block;
  margin-bottom: 0.3rem;
  color: rgb(15, 41, 80);
  background-color: rgb(240, 190, 80);
}

.home-swag-support .swag-support-button-link .go-to-swag-support-button:hover,
.home-swag-support .swag-support-button-link .go-to-swag-support-button:active {
  color: rgb(240, 190, 80);
  background-color: rgb(15, 41, 80);
  border-color: rgb(240, 190, 80);
}

.home-swag-support .swag-support-button-link .go-to-swag-support-button:focus {
  box-shadow: 0 0 0 0.25rem rgba(240, 190, 80, 0.5);
}

@media only screen and (max-width: 767px) {
  .home-swag-support .home-swag-support-container {
    flex-wrap: wrap;
  }

  .home-swag-support .home-support-container,
  .home-swag-support .home-swag-container {
    width: unset;
  }

  .home-swag-support .home-support-container {
    height: unset;
  }

  .home-swag-support .home-support-container .home-support-image {
    height: 50px;
  }

  .home-swag-support .home-swag-container {
    margin-top: 2rem;
  }
}
