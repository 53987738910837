.media-tab-choir-vibes .carousel.slide {
  height: 35rem;
}

.media-tab-choir-vibes.is-authenticated .carousel.slide {
  height: 39rem;
}

.media-tab-choir-vibes .carousel-inner {
  height: 100%;
}

.media-tab-choir-vibes .carousel-item {
  height: 100%;
}

.media-tab-choir-vibes.is-authenticated .carousel-item {
  height: 90%;
}

.media-tab-choir-vibes.is-authenticated .carousel-item.active {
  display: flex;
  flex-direction: column;
}

.media-tab-choir-vibes .carousel-item img {
  height: 85%;
  object-fit: contain;
}

.media-tab-choir-vibes .carousel-item p {
  text-align: center;
  margin-top: 0.5rem;
}
