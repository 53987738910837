.home-gallery .gallery-items .gallery-item-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  width: 100%;
  position: relative;
}

.home-gallery .gallery-items .gallery-item-container .home-gallery-photo-edit-button {
  position: absolute;
  left: 10%;
  top: 0;
}

.home-gallery .gallery-item-image {
  width: 90%;
  object-fit: cover;
  border-radius: 20px;
  flex: 1 1 0;
}

.home-gallery .gallery-items .gallery-item-heading {
  font-size: calc(0.5rem + 3vw);
  font-weight: 900;
  width: 100%;
  text-align: center;
  margin-bottom: 1rem;
}

.home-gallery .gallery-items .gallery-item-image,
.home-gallery .gallery-items .gallery-item-heading {
  transform: translateY(-30%);
  opacity: 0;
}

.home-gallery .gallery-items .gallery-item-image.is-visible,
.home-gallery .gallery-items .gallery-item-heading.is-visible {
  opacity: 1;
  transform: none;
  transition: opacity 1s ease-out, transform 1s ease-out;
}

@media only screen and (max-width: 767px) {
  .home-gallery .gallery-items .gallery-item-container:nth-child(n+2) {
    margin-top: 2rem;
  }
}
