.home-upcoming-events {
  padding: 10vh 2rem;
}

.home-upcoming-events .loading-container {
  margin-top: 0;
}

.home-upcoming-events .loading-image-wrapper {
  display: flex;
  align-items: center;
}

.home-upcoming-events .home-upcoming-events-title {
  text-align: center;
  font-weight: 900;
  font-size: calc(0.8rem + 3vw);
  margin-bottom: 2rem;
}

.home-upcoming-events .next-upcoming-events {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.home-upcoming-events .next-event {
  padding: 1rem;
}

.home-upcoming-events .next-event-title {
  font-weight: 900;
  font-size: calc(0.6rem + 1.8vw);
}

.home-upcoming-events .next-event-date,
.home-upcoming-events .next-event-date-separator,
.home-upcoming-events .next-event-start-time {
  font-size: calc(0.5rem + 1vw);
}

.home-upcoming-events .next-event-date-separator {
  padding: 0 1rem;
}

.home-upcoming-events .events-link {
  text-decoration: none;
  display: flex;
  justify-content: center;
  margin-top: 3rem;
}

.home-upcoming-events .events-link .go-to-events-button {
  font-size: calc(0.5rem + 1.5vw);
  display: block;
  margin-bottom: 0.3rem;
  color: rgb(15, 41, 80);
  background-color: rgb(240, 190, 80);
}

.home-upcoming-events .events-link .go-to-events-button:hover,
.home-upcoming-events .events-link .go-to-events-button:active {
  color: rgb(240, 190, 80);
  background-color: rgb(15, 41, 80);
  border-color: rgb(240, 190, 80);
}

.home-upcoming-events .events-link .go-to-events-button:focus {
  box-shadow: 0 0 0 0.25rem rgba(240, 190, 80, 0.5);
}

.home-upcoming-events-title,
.next-event,
.events-link {
  transform: translateY(-30%);
  opacity: 0;
}

.home-upcoming-events-title.is-visible,
.next-event.is-visible,
.events-link.is-visible {
  opacity: 1;
  transform: none;
  transition: opacity 1s ease-out, transform 1s ease-out;
}
