.home-carousel {
  padding: 10vh 2rem;
}

.home-carousel .carousel.slide {
  height: 75vh;
  min-height: 40rem;
}

.home-carousel.is-authenticated .carousel.slide {
  height: 39rem;
}

.home-carousel .carousel-inner {
  height: 100%;
}

.home-carousel .carousel-item {
  height: 100%;
}

.home-carousel.is-authenticated .carousel-item {
  height: 90%;
}

.home-carousel.is-authenticated .carousel-item.active {
  display: flex;
  flex-direction: column;
}
