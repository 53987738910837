.choristers-container {
  display: flex;
  flex-wrap: wrap;
  margin: 1.5rem 0 0.5rem;
}


@media only screen and (max-width: 543px) {
  .choristers-container {
    justify-content: space-around;
  }

  .choristers-container::after {
    content: "";
    flex-basis: 150px;
  }
}
