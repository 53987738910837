.add-or-edit-choir-vibes-photo-form-container .choir-vibes-photo-number {
  position: absolute;
  top: 4%;
  left: 4%;
}

.add-or-edit-choir-vibes-photo-form-container .choir-vibes-photo-image-container {
  height: 40vh;
  display: flex;
  justify-content: center;
}

.add-or-edit-choir-vibes-photo-form-container .choir-vibes-photo-image {
  height: 100%;
}

.add-or-edit-choir-vibes-photo-form-container .choir-vibes-photo-description {
  text-align: center;
  width: 100%;
  margin-top: 1rem;
}


.add-or-edit-choir-vibes-photo-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 1rem;
}

.add-or-edit-choir-vibes-photo-form .mb-3 {
  width: 100%;
}

.upload-container {
  display: flex;
  align-items: flex-end;
}

.add-or-edit-choir-vibes-photo-form .mb-3 .btn.btn-primary {
  width: unset;
  padding: 0.2rem 0.7rem;
  margin-top: 0.2rem;
  margin-right: 0.2rem;
}

.add-or-edit-choir-vibes-photo-form .progress {
  margin-top: 0.5rem;
  width: 100%;
}

.add-or-edit-choir-vibes-photo-form .btn.btn-primary {
  width: 80px;
}
