.audio-title-and-delete-container {
  margin-bottom: 0.3rem;
  display: flex;
  align-items: flex-end;
}

.audio-title-and-delete-container .audio-title {
  font-size: 1.2rem;
  font-weight: 500;
  margin-bottom: 0;
}

.audio-title-and-delete-container .audio-delete-button {
  margin-left: 1rem;
}

.audio-details-container {
  margin-bottom: 0.3rem;
}

.audio-sub-title{
  color: rgb(176 129 42);
}

.audio-year {
  color: rgb(176 129 42);
  font-size: 0.8rem;
  padding-left: 0.8rem;
}

.audio-container .loading-container {
  width: 640px;
  max-width: 100%;
  margin-top: 0;
  padding: 0;
}

.audio-container .loading-container .loading-image-wrapper {
  height: 60px;
}

.audio-container .loading-container img {
  height: 100%;
}
