.event-title,
.event-time-and-delete {
  margin-bottom: 0;
}

.event-title-and-date {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.event-title {
  font-size: 105%;
  font-weight: 900;
  text-shadow: 0 0 3px black;
}

.event-title-and-date .event-date {
  padding-left: 1rem;
}

.event-time-and-delete {
  padding-left: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.event-time-and-delete .event-delete-button {
  margin-left: 1rem;
}
