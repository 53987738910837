.modal-body .resource-links {
  display: flex;
}

.modal-body .resource-links .resource-link {
  margin-right: 2rem;
  font-weight: 900;
  text-shadow: 0 0 1px black;
}

.modal-body .resource-links .resource-link:nth-last-child(1) {
  margin-right: 0;
}

@media only screen and (max-width: 700px) {
  .modal-body .resource-links {
    display: flex;
    flex-direction: column;
  }

  .modal-body .resource-links .resource-link {
    margin-right: 0rem;
    margin-bottom: 1rem;
  }

  .modal-body .resource-links .resource-link:nth-last-child(1) {
    margin-bottom: 0;
  }
}
