.order-swag-modal .modal-title {
  font-size: 1.5rem;
  font-weight: 900;
  padding-bottom: 1rem;
  margin-bottom: 1.75rem;
  position: relative;
  width: fit-content;
}

.order-swag-modal .modal-title::after {
  position: absolute;
  content: "";
  width: 70%;
  height: 0.125rem;
  top: calc(100% + 0.025rem);
  left: 0;
  background-color: rgba(255, 255, 255, 0.5);
}

.order-swag-modal .modal-body {
  display: flex;
  flex-wrap: wrap;
}

.order-swag-modal .modal-body .swag-item {
  height: unset;
  width: 55%;
  margin: 0;
  display: flex;
  flex-direction: column;
}

.order-swag-modal .modal-body .swag-item .swag-group {
  line-height: 2rem;
}

.order-swag-modal .modal-body .swag-item .swag-image {
  height: unset;
  width: 90%;
  max-width: 450px;
  margin-top: 0.1rem;
}

.order-swag-modal .modal-footer .btn.btn-primary svg {
  margin-left: 0.2rem;
}

@media only screen and (max-width: 1000px) {
  .order-swag-modal .modal-body {
    flex-direction: column;
  }

  .order-swag-modal .modal-body .swag-item,
  .order-swag-modal .modal-body .swag-item .swag-image,
  .order-swag-modal .modal-body .order-form-container {
    width: 100%;
  }

  .order-swag-modal .modal-body .order-form-container {
    margin-left: 0;
    margin-top: 1rem;
  }
}
