.edit-home-gallery-photo-form-container .home-gallery-photo-number {
  position: absolute;
  top: 4%;
  left: 4%;
}

.edit-home-gallery-photo-form-container .home-gallery-photo-title {
  font-weight: 900;
  font-size: calc(0.5rem + 3vw);
  color: rgb(239, 167, 33);
  text-shadow: 0 0 5px black;
  width: 100%;
  text-align: center;
  margin-bottom: 1rem;
}

.edit-home-gallery-photo-form-container .home-gallery-photo-image-container {
  height: 40vh;
  display: flex;
  justify-content: center;
}

.edit-home-gallery-photo-form-container .home-gallery-photo-image {
  height: 100%;
}


.edit-home-gallery-photo-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 1rem;
}

.edit-home-gallery-photo-form .mb-3 {
  width: 100%;
}

.upload-container {
  display: flex;
  align-items: flex-end;
}

.edit-home-gallery-photo-form .mb-3 .btn.btn-primary {
  width: unset;
  padding: 0.2rem 0.7rem;
  margin-top: 0.2rem;
  margin-right: 0.2rem;
}

.edit-home-gallery-photo-form .progress {
  margin-top: 0.5rem;
  width: 100%;
}

.edit-home-gallery-photo-form .btn.btn-primary {
  width: 80px;
}
