.home-gallery {
  padding: 10vh 2rem;
  background-color: rgb(4, 27, 61);
}

.home-gallery .gallery-title {
  text-align: center;
  font-size: calc(0.8rem + 3vw);
  margin-bottom: 2rem;
  opacity: 0;
}

.home-gallery .gallery-title.is-visible {
  opacity: 1;
  transition: opacity 1s ease-out;
}

.home-gallery .loading-container {
  margin-top: 0;
  max-height: 40vh;
}

.home-gallery .gallery-items {
  display: flex;
}

@media only screen and (max-width: 767px) {
  .home-gallery .gallery-items {
    flex-wrap: wrap;
  }
}
