footer {
  padding: 2rem;
  margin-bottom: 4rem;
  background-color: rgb(240, 190, 80);
  color: rgb(53, 79, 139);
  display: flex;
  justify-content: space-between;
}

.footer-list {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
}

.footer-list li {
  padding: 0 0.5rem;
}

.footer-button {
  font-weight: 900;
  font-size: 1.1rem;
  cursor: pointer;
}

.footer-button:hover {
  color: white;
  text-shadow: 0 0 3px black;
  transform: scale3d(1.1, 1.1, 1.1);
  transition: transform ease-out 0.1s;
}

.login-link,
.login-link:hover,
.login-link:focus-visible {
  color: inherit;
  text-decoration: none;
  cursor: inherit;
}

@media only screen and (max-width: 900px) {
  footer {
    flex-direction: column;
  }

  .footer-list {
    flex-direction: column;
    align-items: flex-start;
  }

  .footer-list-separator {
    display: none;
  }

  .footer-list li {
    padding: 0.2rem 0;
  }

  .made-by {
    padding-top: 0.2rem;
  }
}
