.support-container {
  width: 40%;
}

h2.support-heading {
  font-size: 1.5rem;
  font-weight: 900;
  padding-bottom: 1rem;
  margin-bottom: 1.75rem;
  position: relative;
  width: fit-content;
}

h2.support-heading::after {
  position: absolute;
  content: "";
  width: 70%;
  height: 0.125rem;
  top: calc(100% + 0.025rem);
  left: 0;
  background-color: rgba(0,0,0,.4);
}

.supprt-header {
  margin-bottom: 2rem;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
}

.supprt-header .support-header-text {
  margin-bottom: 0.1rem;
  margin-right: 1rem;
}

.supprt-header .support-header-text svg {
  color: rgb(240, 190, 40);
}

.support-options-container {
  display: flex;
  flex-direction: column;
}

.support-options-text-container {
  min-height: fit-content;
}

.support-options-text-container h3 {
  font-size: 1.35rem;
}

.support-options-text-container ul {
  list-style: circle;
  padding-left: 2rem;
  margin-bottom: 1rem;
  margin-block-start: 0.6em;
  padding-inline-start: 32px;
  line-height: 1.5rem;
}

.donate-header {
  font-size: 1.35rem;
  font-weight: 900;
}

.paypal-container {
  height: fit-content;
  margin-bottom: 2rem;
}

.paypal-link-container {
  height: 5em;
}

.paypal-button {
  height: 100%;
}

.paypal-image-container {
  height: 15rem;
}

.paypal-code {
  height: 100%;
}

.venmo-image-container {
  height: 20rem;
}

.venmo-code {
  height: 80%;
}

.paypal-container p,
.venmo-image-container p {
  margin-bottom: 1rem;
}

@media only screen and (max-width: 812px) {
  .paypal-container,
  .venmo-image-container {
    width: 100%;
  }
}

@media only screen and (max-width: 335px) {
  .venmo-code {
    height: 65%;
  }
}


@media only screen and (max-width: 1108px) {
  .support-container {
    width: 47%;
  }
}

@media only screen and (max-width: 950px) {
  .support-container {
    width: 54%;
  }
}

@media only screen and (max-width: 950px) {
  .support-container {
    width: 59%;
  }
}

@media only screen and (max-width: 767px) {
  .support-container {
    width: 100%;
  }
}
