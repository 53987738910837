.home-carousel .carousel-item img {
  height: 85%;
  object-fit: scale-down;
}

.home-carousel .carousel-item p {
  text-align: center;
  margin-top: 0.5rem;
}

.home-carousel .home-carousel-photo-action-buttons {
  align-self: center;
}

.home-carousel .home-carousel-photo-edit-button {
  margin: 0 4rem;
}
