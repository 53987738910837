.contact .contact-info-container {
  display: flex;
}

.contact .contact-info-container .staff-member-contact-photo {
  width: 300px;
  height: 300px;
  object-fit: cover;
  margin-right: 3rem;
}

.contact .contact-info-container .staff-member-contact-photo-details {
  font-size: 1rem;
  font-weight: 900;
  margin-top: 0.2rem;
}

.contact .contact-info-container address {
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
}

.contact .contact-info-container .address-header {
  font-size: calc(1.5rem + 0.3vw);
}

.contact .contact-info-container address > a,
.contact .contact-info-container address > p {
  padding-top: 0.3rem;
  margin-top: 1.3rem;
  font-size: 1.4rem;
  font-weight: 500;
}

.contact .contact-info-container address > a {
  text-decoration: none;
  width: max-content;
}

.contact .contact-info-container address > a,
.contact .contact-info-container address > a:visited {
  color: rgb(240, 190, 100);
}

.contact .contact-info-container address > a:hover {
  color: white;
  transition: color 0.35s;
}

.contact .contact-info-container address > a > svg,
.contact .contact-info-container address > p > svg {
  color: rgb(240, 190, 100);
  width: 23px !important;
}

.contact .contact-info-container address svg.svg-inline--fa {
  margin-right: 0.7rem;
}

@media only screen and (max-width: 767px) {
  .contact .contact-info-container {
    flex-direction: column-reverse;
  }

  .contact .contact-info-container address > a,
  .contact .contact-info-container address > p {
    margin-top: 1rem;
    font-size: 1.3rem;
  }
}
