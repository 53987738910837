.checkout .cart-swag-item {
  height: 40vh;
  display: flex;
  margin-bottom: 3rem;
  margin-right: 2rem;
}

.checkout .cart-swag-image-container {
  height: 100%;
  width: 70%;
}

.checkout .cart-swag-image {
  height: 100%;
}

.checkout .cart-item-details {
  padding-left: 1rem;
  width: 100%
}

.checkout .cart-swag-group {
  font-weight: 900;
  font-size: 1.4rem;
}

.checkout .cart-swag-category {
  display: inline;
}

.checkout .cart-swag-size {
  font-size: 1.1rem;
  margin-bottom: 0.5rem;
}

.checkout .cart-quantity-subtract,
.checkout .cart-item-quantity,
.checkout .cart-quantity-add {
  color:rgb(240, 190, 80);
  border-color:rgb(240, 190, 80);
  font-size: 1rem;
}

.checkout .cart-quantity-subtract,
.checkout .cart-quantity-add {
  display: flex;
  align-items: center;
}

.checkout .cart-quantity-subtract > svg,
.checkout .cart-quantity-add > svg {
  height: 0.7em;
}

.checkout .cart-quantity-subtract:hover,
.checkout .cart-quantity-add:hover {
  background-color: rgba(117, 149, 198, 0.40);
}

.checkout .cart-quantity-subtract:focus,
.checkout .cart-quantity-add:focus {
  box-shadow: 0 0 0 3px rgba(77, 119, 181, 0.50);
}

.checkout .cart-quantity-subtract {
  border-right: 0;
}

.checkout .cart-quantity-subtract.is-one {
  color: #ac2424;
  background-color: transparent;
  cursor: pointer;
  font-size: 1.2rem;
}

.checkout .cart-quantity-subtract.is-one:hover {
  color: #dc3545;
}

.checkout .cart-quantity-subtract.is-one:focus {
  box-shadow: none;
  color: #dc3545;
}

.checkout .cart-item-quantity {
  opacity: 1;
  border-left: 0;
  border-right: 0;
}

.checkout .cart-quantity-add {
  border-left: 0;
}

.checkout .cart-swag-quantity-container {
  position: relative;
  width: fit-content;
}

.checkout .cart-swag-quantity-container .checkout-item-delete-button {
  position: absolute;
  right: -2.5rem;
  top: 50%;
  transform: translateY(-50%);
}

@media only screen and (max-width: 650px) {
  .checkout .cart-swag-item {
    height: unset;
    width: 100%;
    flex-direction: column;
    align-items: center;
    padding-right: 0;
  }

  .checkout .cart-swag-image {
    height: unset;
    width: 100%;
  }

  .checkout .cart-item-details {
    margin: 1rem 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
