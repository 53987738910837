.fc {
  width: calc(70vw - 1rem);
}

.fc a {
  color: inherit;
}

.fc-col-header-cell-cushion {
  text-decoration: none;
}

a.fc-daygrid-day-number,
.fc-timeGridWeek-view a.fc-col-header-cell-cushion,
a.fc-list-day-text,
a.fc-list-day-side-text {
  color: rgb(240, 190, 100);
}

.fc .fc-day-other .fc-daygrid-day-top {
  opacity: 0.5;
}

.fc-event-time {
  font-weight: 900;
}

.fc-event-title {
  font-style: italic;
}

.fc .fc-list-table .fc-list-day-cushion.fc-cell-shaded {
	background-color: rgb(15, 41, 80);
}

.fc .fc-list-table .fc-list-event:hover td {
  background-color: rgb(10, 28, 55);
}

.fc-daygrid-event.fc-daygrid-dot-event {
  white-space: normal;
  text-align: left;
  overflow: hidden;

  display: block;
  border: 1px solid #3788d8;
  background-color: #3788d8;

  color: #fff;
}

.fc-daygrid-event.fc-daygrid-dot-event .fc-daygrid-event-dot {
  display: inline-block;
  border: 1.5px solid #fff;
  vertical-align: middle;
}

.fc-daygrid-event.fc-daygrid-dot-event .fc-event-time {
  display: inline-block;
  font-size: 0.7rem;
  vertical-align: initial;
}

@media only screen and (max-width: 767px) {
  .fc {
    width: calc(100vw - 2rem);
  }
}

@media only screen and (max-width: 607px) {
  .fc button.fc-button.fc-today-button {
    margin-top: 5px;
  }
}

@media only screen and (max-width: 500px) {
  div.fc-toolbar-chunk:nth-child(2) {
    padding-left: 5px;
    padding-right: 5px;
  }
}
@media only screen and (max-width: 465px) {
  .fc .fc-toolbar.fc-header-toolbar {
    flex-wrap: wrap;
  }

  div.fc-toolbar-chunk:nth-child(3) {
    margin-top: 5px;
  }
}
