.modal-body {
  padding-left: 0;
}

.modal-body .confirm-cart-swag-image {
  display: block;
  margin-top: 1rem;
  height: 150px;
}

.modal-footer {
  padding-right: 0;
}

.modal-footer button.btn:last-child {
  margin-right: 0;
}
