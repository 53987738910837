.cart-dropdown .cart-swag-item {
  height: 130px;
  display: flex;
}

.cart-dropdown .cart-swag-item:nth-last-child(n+2) {
  border-bottom: rgba(15, 41, 80, 0.5) dotted 1px;
}

.cart-dropdown .cart-swag-item:nth-child(n+2) {
  margin-top: 1rem;
}

.cart-dropdown .cart-swag-image-container {
  height: 80%;
  width: 70%;
}

.cart-dropdown .cart-swag-image {
  height: 100%;
}

.cart-dropdown .cart-item-details {
  padding-left: 0.3rem;
  width: 100%
}

.cart-dropdown .cart-swag-group {
  font-weight: 900;
  font-size: 1.1rem;
}

.cart-dropdown .cart-swag-category {
  display: inline;
}

.cart-dropdown .cart-swag-size {
  font-size: 0.9rem;
  margin-bottom: 0.1rem;
}

.cart-dropdown .cart-quantity-subtract,
.cart-dropdown .cart-item-quantity,
.cart-dropdown .cart-quantity-add {
  color:rgb(15, 41, 80);
  border-color:rgb(15, 41, 80);
}

.cart-dropdown .cart-quantity-subtract,
.cart-dropdown .cart-quantity-add {
  display: flex;
  align-items: center;
}

.cart-dropdown .cart-quantity-subtract > svg,
.cart-dropdown .cart-quantity-add > svg {
  height: 0.7em;
}

.cart-dropdown .cart-quantity-subtract:hover,
.cart-dropdown .cart-quantity-add:hover {
  background-color: rgba(117, 149, 198, 0.40);
}

.cart-dropdown .cart-quantity-subtract:focus,
.cart-dropdown .cart-quantity-add:focus {
  box-shadow: 0 0 0 3px rgba(77, 119, 181, 0.50);
}

.cart-dropdown .cart-quantity-subtract {
  border-right: 0;;
}

.cart-dropdown .cart-item-quantity {
  opacity: 1;
  border-left: 0;
  border-right: 0;
}

.cart-dropdown .cart-quantity-add {
  border-left: 0;
}

.cart-dropdown .cart-swag-quantity-container {
  position: relative;
  width: fit-content;
}

.cart-dropdown .cart-quantity-subtract.is-one {
  color: #ac2424;
  background-color: transparent;
  cursor: pointer;
  font-size: 1.2rem;
}

.cart-dropdown .cart-quantity-subtract.is-one:hover {
  color: #dc3545;
}

.cart-dropdown .cart-quantity-subtract.is-one:focus {
  box-shadow: none;
  color: #dc3545;
}
