.navbar {
  background-color: rgba(0, 0, 0, 0.85);
  color: #fff;
  padding: 2.4rem;
  height: 15vh;
  font-size: 1.8rem;
  font-weight: 700;
}

.navbar-toggler {
  position: absolute;
  border: 0;
}

.navbar-toggler:focus {
  box-shadow: unset;
}

.navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 1%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e") !important;
}

#logo-link, #logo {
  font-size: 0.9rem;
  height: 100px;
  position: absolute;
  left: 2rem;
  transform: translateX(0%);
  margin: 0;
  padding: 0;
  z-index: 100;
}

#home-page-link-tooltip {
  left: -20px !important; 
}

.navbar-toggler.collapsed>.close, .navbar-toggler:not(.collapsed)>.navbar-toggler-icon {
  background-image: url("https://cdn3.iconfinder.com/data/icons/ui-fundamentals/100/UI-F-32-512.png") !important;
  filter: invert(100%);
}

.fa-times {
  cursor: pointer;
}

.navbar-light .instagram-link {
  position: absolute;
  bottom: 1rem;
  right: 5rem;
  height: 40px;
}

.navbar-light .instagram-logo {
  height: 100%;
  vertical-align: initial;
}

.navbar-light .logout-text {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0;
  margin: 0;
}

.navbar-light .logout-text .btn.btn-outline-danger {
  border: none;
}

@media only screen and (max-height: 740px) {
  #logo-link, #logo {
    height: 80px;
  }
}
@media only screen and (max-height: 740px) {
  #logo-link, #logo {
    height: 80px;
  }
}

@media only screen and (max-height: 610px) {
  #logo-link, #logo {
    height: 60px;
  }
}

@media only screen and (max-width: 1100px) {
  #logo-link, #logo {
    left: 1.5rem;
  }
}

@media only screen and (max-width: 990px) {
  #logo-link, #logo {
    left: 1rem;
  }
}

@media only screen and (max-width: 767px) {
  #logo-link, #logo {
    left: 50%;
    transform: translateX(-50%);
  }
}

@media only screen and (max-device-width: 300px) {
  .navbar-light .logout-text .btn {
    font-size: 0.8rem;
  }
}

@media only screen and (max-device-height: 600px) {
  #logo-link, #logo {
    height: 60px;
  }
}

@media only screen and (min-width: 1000px) {
  .navbar-light .logout-text .btn {
    font-size: 1.2rem;
  }
}

@media only screen and (min-width: 1200px) {
  .navbar-light .logout-text .btn {
    font-size: 1.5rem;
  }
}
