.add-audio-form-container > .audio-event-type {
  font-size: calc(0.75rem + 1vw);
  color: rgba(239, 167, 33, 0.7);
  position: absolute;
  top: 3%;
  left: 3%;
}

.add-audio-form-container .audio-container {
  margin-top: 1rem;
}

.add-audio-form {
  width: 100%;
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.add-audio-form .mb-3 {
  width: 100%;
}

.add-audio-form .mb-3 .btn.btn-primary {
  width: unset;
  padding: 0.2rem 0.7rem;
  margin-top: 0.2rem;
  margin-right: 0.2rem;
}

.add-audio-form .btn.btn-primary {
  width: 80px;
}

.upload-container {
  display: flex;
  align-items: flex-end;
}

.add-audio-form .progress {
  margin-top: 0.5rem;
  width: 100%;
}
