.video-container {
  width: 100%;
  display: inline-block;
  margin-bottom: 2.5rem;
}

.video-title-and-delete-container {
  margin-bottom: 0.3rem;
  display: flex;
  align-items: flex-end;
}

.video-title-and-delete-container .video-title {
  font-size: 1.2rem;
  font-weight: 500;
  margin-bottom: 0;
}

.video-title-and-delete-container .video-delete-button {
  margin-left: 1rem;
}

.video-details-container {
  margin-bottom: 0.3rem;
}

.video-sub-title{
  color: rgb(176 129 42);
}

.video-year {
  color: rgb(176 129 42);
  font-size: 0.8rem;
  padding-left: 0.8rem;
}

.video-container .loading-container {
  height: 100%;
  width: 100%;
  margin-top: 0;
  padding: 0;
  aspect-ratio: auto 16 / 9;
}

.video-container .loading-container .loading-image-wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.video-container .loading-container img {
  height: 100%;
}
