.staff-member-name-and-edit-container {
  display: flex;
  align-items: center;
}

.staff-member-name-and-edit-container .staff-member-edit-button {
  margin: 0 2rem;
}

.staff-member-name {
  margin-bottom: 0.5rem;
  font-size: 2.5rem;
  font-weight: 900;
  color: rgb(240, 190, 100);
  text-shadow: 0 1px 0 black;
}

.staff-member-photo-and-blurb-container {
  display: flex;
  width: 100%;
}

.staff-member-photo-container {
  width: calc(15em + 15%);
}

.staff-member-photo {
  width: 90%;
  object-fit: cover;
}

.staff-member-blurb-container {
  width: calc(85% - 15em - 3rem);
}

.staff-member-blurb-container .staff-member-blurb-paragraph {
  line-height: 1.5;
  margin-bottom: 1rem;
}

@media only screen and (max-width: 800px) {
  .staff-member-container:not(.preview) .staff-member-name-and-edit-container .staff-member-edit-button {
    margin-right: 0;
  }

  .staff-member-container:not(.preview) .staff-member-photo-and-blurb-container {
    flex-direction: column;
  }

  .staff-member-container:not(.preview) .staff-member-photo-container {
    width: 50%;
  }

  .staff-member-container:not(.preview) .staff-member-photo {
    width: 100%;
  }

  .staff-member-container:not(.preview) .staff-member-blurb-container {
    width: 100%;
    margin-top: 1rem;
  }
}

@media only screen and (max-width: 700px) {
  .staff-member-container:not(.preview) .staff-member-photo-container {
    width: 60%;
  }
}

@media only screen and (max-width: 600px) {
  .staff-member-container:not(.preview) .staff-member-photo-container {
    width: 70%;
  }
}

@media only screen and (max-width: 500px) {
  .staff-member-container:not(.preview) .staff-member-photo-container {
    width: 80%;
  }
}

@media only screen and (max-width: 400px) {
  .staff-member-container:not(.preview) .staff-member-photo-container {
    width: 90%;
  }
}

@media only screen and (max-width: 300px) {
  .staff-member-container:not(.preview) .staff-member-photo-container {
    width: 100%;
  }
}

@media only screen and (max-width: 1100px) {
  .staff-member-container.preview .staff-member-name-and-edit-container .staff-member-edit-button {
    margin-right: 0;
  }

  .staff-member-container.preview .staff-member-photo-and-blurb-container {
    flex-direction: column;
  }

  .staff-member-container.preview .staff-member-photo-container {
    width: 100%;
  }

  .staff-member-container.preview .staff-member-photo {
    width: 100%;
  }

  .staff-member-container.preview .staff-member-blurb-container {
    width: 100%;
    margin-top: 1rem;
  }
}
